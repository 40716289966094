import { HttpClient } from '@angular/common/http';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
export function OnIFrameSave(data) {
}
export function OnIFrameError(error) {
  if (!error) {
    return;
  }
  window.alert(error);
}
@Component({
  selector: 'app-hostedpayment-dialog',
  templateUrl: './hostedpayment-dialog.component.html',
  styleUrls: ['./hostedpayment-dialog.component.scss']
})

export class HostedpaymentDialogComponent implements OnInit {
  public paymentData: any = {};
  public iFrameUrl: any;
  constructor(public sanitizer: DomSanitizer, public http: HttpClient, public dialogRef: MatDialogRef<HostedpaymentDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dialogRef.disableClose = true;
  }
  @HostListener('window:message', ['$event'])
  onMessage(e) {
    if (e.data.event == 'cardSaved') {
      // e.data nexio card event
      this.dialogRef.close(e.data.data);
    } else if (e.data.isTestCardEvent == true) {
      // e.data test card event
      this.dialogRef.close(e.data);
    } else{
      // e.data. has no event in NMI case so handling this case
      this.dialogRef.close(e.data);
    }
  }
  @HostListener('window:OnIFrameSave', ['$event'])
  OnIFrameSave(e) {
  }
  @HostListener('window:OnIFrameError', ['$event'])
  OnIFrameError(e) {

  }
  ngOnInit(): void {
    this.paymentData = this.data;
    //old width="{{getIframeWidth()}}" of iframe, new given 100% for responsive
    this.setUpIframeMethods();
    this.executePaymentScripts(this.paymentData.Script);
    this.iFrameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.paymentData.IFrameUrl);
  }
  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
  getIframeHeight(): string {
    return this.paymentData.Height;
  }

  getIframeUrl(): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.paymentData.IFrameUrl);
  }

  getIframeWidth(): string {
    return this.paymentData.Width;
  }


  executePaymentScripts(html) {
    // Extract, load and execute <script src> tags
    // Extract and execute <script> tags with inline javascript

    var externalScripts = this.getExternalScripts(html);
    html = this.removeExternalScriptTags(html);

    this.runExternalScripts(externalScripts).then(() => {
      var inlineScripts = this.getInlineScripts(html);
      this.runInlineScripts(inlineScripts);
    });
  }

  getExternalScripts(html) {
    var externalScriptOpenRegex = /<script[^>]*src="[^"]*"[^>]*>/g;
    var srcAttrRegex = /src="([^"]*)"/;

    var scriptTags = html.match(externalScriptOpenRegex);

    if (!scriptTags) {
      return [];
    }

    return scriptTags.map((tag) => {
      var link = srcAttrRegex.exec(tag);

      if (!link) {
        return null;
      }

      // Index 1 is the first capture group result
      return link[1];
    }).filter(function (link) {
      return !!link;
    });
  }

  getInlineScripts(html) {
    var inlineScriptTagRegex = /<script[^>]*>([\s\S]*)<\/script>/g;
    var scriptTags = html.match(inlineScriptTagRegex);

    if (!scriptTags) {
      return [];
    }

    return scriptTags.map((tag) => {
      var script = inlineScriptTagRegex.exec(tag);

      if (!script) {
        return null;
      }

      // Index 1 is the first capture group result
      return script[1];
    }).filter(function (script) {
      return !!script;
    });
  }

  removeExternalScriptTags(html) {
    var externalScriptRegex = /<script[^>]*src="[^"]*"[^>]*>.*<\/script>/g;
    return html.replace(externalScriptRegex, '');
  }

  runExternalScripts(urlArray) {
    const promise = new Promise((resolve, reject) => {
      var firstScript = urlArray.splice(0, 1)[0];
      if (!firstScript) {
        resolve(true);
        return promise;
      }

      this.http.get<any>(firstScript).subscribe(() => {
        this.runExternalScripts(urlArray);
        resolve(true);
      });
    });
    return promise;
  }
  runInlineScripts(scriptArray) {
    window.eval(scriptArray.join('\n;\n'));
  }
  setUpIframeMethods() {
    window['OnIFrameSave'] = OnIFrameSave;
    window['OnIFrameError'] = OnIFrameError;
  }
  onload() {
    //this.save(data);
  }
}
