import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-loyalty-rewards',
  templateUrl: './loyalty-rewards.component.html',
  styleUrls: ['./loyalty-rewards.component.scss']
})
export class LoyaltyRewardsComponent implements OnInit {

  displayForCarousel = [
    {
      img: "../../../../assets/images/new/hero-bg.jpg",
      startext:
        "Super products, especially now when we need to keep our immune system strong!",
      author: "Dr. Karl Anderson",
      location: "Meridian, ID",
      small: true,
      medium: false,
      left: false,
      leftbig: false,
      boxTitle: 'COMMON SENSE',
      boxContent: 'Loyalty Rewards Program'
    }
  ];

  membershipPerks: Array<any>
  membershipSteps: Array<any>
  percentagePoints: Array<any>
  frequentlyAskedQuestions: Array<any>

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {

    this.membershipPerks = [
      { imageUrl: '../../../../assets/images/loyalty-rewards/easy-delivery.png', title: 'Easy Delivery', content: 'Wholesale Pricing and Easy Auto Delivery' },
      { imageUrl: '../../../../assets/images/loyalty-rewards/rewards.png', title: '15% Rewards', content: 'Earn Up to 15% Back in Rewards.' },
      { imageUrl: '../../../../assets/images/loyalty-rewards/free.png', title: 'Earn Free Products', content: 'Refer others and earn free product every month!' },
    ]

    this.membershipSteps = [
      { imageUrl: '../../../../assets/images/loyalty-rewards/money.png', title: 'Step 1', content: '<div class="content-para"> Create your Preferred Customer <span class="nunito-bold f-s-16"> <b>Loyalty Rewards </b></span> account and pay the $9.95 annual fee.<div>' },
      { imageUrl: '../../../../assets/images/loyalty-rewards/heart.png', title: 'Step 2', content: '<div class="content-para"> Purchase your favorite product today AND Create a loyalty order of your <span class="nunito-bold f-s-16"><b> favorite products </b></span> to be processed during the 1st to the 5th of the month.<div>' },
      { imageUrl: '../../../../assets/images/loyalty-rewards/speak.png', title: 'Step 3', content: '<div class="content-para"> Enjoy a discount on every product purchase, earn up to <span class="nunito-bold f-s-16"> 15% in Rewards </span>, and if you share this program with others, you can even earn free products monthly!<div>' },
    ]

    this.percentagePoints = [
      { percent: '8', title: 'Between Months 1 & 3', content: 'Earn 8% percent of the total AC of your order during these months.' },
      { percent: '10', title: 'Between Months 4 & 6', content: 'Earn 10% of the total AC of your order during these months.' },
      { percent: '12', title: 'Between Months 7 & 9', content: 'Earn 12% of the total AC of your order during these months.' },
      { percent: '15', title: 'Months 10 and On', content: 'Earn 15% of the total AC of your order during these months.' },
    ]

    this.frequentlyAskedQuestions = [
      {
        question: 'How many Reward Points will I earn?',
        answer: 'You can earn up to 15% of the total AC from your order in points. The percentage starts at 8% for the first 3 months, then jumps to 10% at months 4-6, then goes to 12% for months 7-9, and then makes a final jump to 15% for months 10 and beyond.'
      },
      {
        question: 'For how long are my Rewards Points valid?',
        answer: `<p class"m-b-12">Your Reward Points will accumulate and remain valid for 365 days from the date they are earned, at which point they will expire if they are not used. </p><p class"m-b-12">
      Points will also expire, and the points account will be reset to zero if a Loyalty Order fails to process by the 5th of each month.  In the case of a missed Loyalty Order, you will also start back at month 1 and receive 8% for the AC of the next successful Loyalty Order processed by the 5th of the current month.</p><p class"m-b-12">
      You may postpone your Loyalty Order up to 2 times a year, for a month at a time, by contacting our support team. In this case you will not lose any rewards points, and your earned percentage tier will remain intact as if your Loyalty Order had processed for the skipped month.  These arrangements must be made prior to the date your Loyalty Order is set to run and cannot be made Retroactively.</p>`
      },
      {
        question: 'Do I earn Rewards Points for Sponsoring a Customer as a Preferred Customer?',
        answer: `<p class"m-b-12">You do not earn Loyalty Reward points on your customer’s orders.  They can only be earned on your personal orders. </p><p class"m-b-12">
      You can however participate in our Refer 3 and Earn Free program by sponsoring 3 or more Preferred Customers that maintain an active Loyalty Order.  Please contact your referring IBO or support for details on this program.</p>`
      },
      {
        question: 'When are my Rewards Points added to my account?',
        answer: `You will see your Rewards Points account update on the first of the month following the date of the successful Loyalty Order.`
      },
      {
        question: 'How do I choose my free products?',
        answer: `Points can be redeemed for free products in the back-office shopping cart.  Just pay any tax and/or shipping cost if applicable.  Rewards orders are not eligible for free shipping and are taxed at the full amount of the product being received.`
      },
      {
        question: 'If I upgrade to an IBO do I lose my accumulated Rewards Points?',
        answer: `If at any time you choose to upgrade your account, you will keep all accumulated points and continue to earn.`
      },
      {
        question: 'Can I cancel my Loyalty Rewards order?',
        answer: `Yes, you may cancel your Loyalty Rewards order at any time.  Just be aware that your accumulated points will immediately reset to zero, and you will start over earning at the 8% tier when you start a new Loyalty Order.`
      }
    ]

  }

  ngOnInit(): void {
  }

  enrollUser() {
    this.router.navigate(['/join'],
      {
        relativeTo: this.activatedRoute,
        queryParams: { type: 3 },
        queryParamsHandling: 'merge'
      }).then(() => { window.location.reload(); });
  }

}
