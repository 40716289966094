import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
	name: 'groupByCount'
})
export class GroupByCountPipe implements PipeTransform {
	transform(value, count) {
        var result = {};
        if (value && value.length > 0) {
            if (!count) {
                count = 1;
            }
            var counter = 0, length = value.length / count;
            for (var i = 0; i < length; i++) {
                result[counter] = value.slice(counter * count, (counter * count) + count);
                counter++;
            }
        }
        return result;
    }
}