import { Component, OnInit, HostBinding, Input, Output, EventEmitter } from '@angular/core';
import { SidebarMenuService } from './sidebar-menu.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SidenavMenu } from './sidebar-menu.model';
import { Router } from '@angular/router';
import { UserServiceModal } from 'src/app/modals/userservice.modal';
import { UserService } from '../services/user.service';
import { AccountService } from '../services/account.service';
import { ProductService } from '../services/product.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogComponentComponent } from '../login-dialog-component/login-dialog-component.component';
import { MembershipDialogComponent } from '../membership-dialog/membership-dialog.component';
import { RestApiService } from '../services/restapi.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class SidebarComponent implements OnInit {
  expanded: boolean;
  backofficelink: string;
  @Input() item: SidenavMenu;
  @Input() depth: number;
  @Output() close = new EventEmitter<string>();
  @Input() children: any
  userServiceModal: UserServiceModal;
  constructor(
    public apiService: RestApiService,
    public dialog: MatDialog,
    private sidenavMenuService: SidebarMenuService,
    public router: Router,
    public userService: UserService,
    public accountService: AccountService,
    public productService: ProductService) {
    this.userServiceModal = this.userService.userServiceModal;
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit() {
    // this.sidenavMenuService.currentUrl.subscribe((url: string) => {
    //   if (this.item.route && url) {
    //     this.expanded = url.indexOf(`/${this.item.route}`) === 0;
    //     // this.ariaExpanded = this.expanded;
    //   }
    // });
  }

  sidemenuCommonFunction(item) {
    if (!item.children || !item.children.length) {
      if (item.displayName == 'Membership') {
        this.router.navigate(['/membership']);
      } else if (item.displayName == 'Loyalty Rewards') {
        this.router.navigate(['/loyalty-rewards']);
      } else if (item.displayName == 'Contact Us') {
        this.router.navigate(['/contact']);
      } else if (item.displayName == 'Join Now') {
        this.router.navigate(['/join']);
      } else if (item.displayName == 'Log In') {
        // const dialogRef = this.dialog.open(LoginDialogComponentComponent, {
        //   width: "600px",
        //   autoFocus: false
        // });
        // dialogRef.afterClosed().subscribe((result) => {
        // });
        window.open('https://office2.commonsenseww.com/#/Login','_blank');
      } else if (item.displayName == 'Shop Now') {
        // const dialogRef = this.dialog.open(MembershipDialogComponent, {
        //   width: '800px',
        //   height: 'auto',
        //   panelClass: 'myapp-no-padding-dialog'
        // });
        // dialogRef.afterClosed().subscribe(result => {
          this.router.navigate(['/shopnow']);
        // });
      } else if (item.displayName == 'Log Out') {
        this.accountService.logout();
      } else if (item.displayName == 'Back Office') {
        let windowReference = window.open();
        let request = {
          CustomerID: this.userService.userServiceModal?.customerData.CustomerId,
          Dest: 'home'
        }
        this.apiService.getCustomerSSOURL(request).subscribe((result) => {
          // this.backofficelink = result.Data
          windowReference.location = result.Data
        })
      }
    }
    // if (item.children && item.children.length) {
    //   this.expanded = !this.expanded;
    // }
  }
  closeDialog(item) {
    this.close.emit('1');
  }

  isLoggedIn() {
    if ((Object.keys(this.userServiceModal.customerData).length) && (this.userServiceModal.customerData.CustomerId) && this.userService.checkIfUserAuthenticatedOrNot()) {
      return true;
    } else {
      return false;
    }
  }
  
}
