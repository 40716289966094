import { Component, OnInit } from "@angular/core";
import { ConfigService } from "../../shared/services/config.service";
import { UserService } from "../../shared/services/user.service";
import { UserServiceModal } from "src/app/modals/userservice.modal";
import * as _ from "lodash";
import { environment } from "src/environments/environment";
import { MatDialog } from "@angular/material/dialog";
import { MembershipDialogComponent } from "../../shared/membership-dialog/membership-dialog.component";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  displayForCarousel = this.configService.localSettings.Company.displayForCarousel
  env: any;
  userService: UserServiceModal;
  typeSelected: any;
  constructor(
    public configService: ConfigService,
    public user: UserService,
    public dialog: MatDialog, private router: Router,
    public activatedRoute: ActivatedRoute,
  ) {
    this.userService = user.userServiceModal;
    this.env = environment;
  }
  ngOnInit() {
    this.typeSelected = this.userService.customerTypeID
  }

  ngAfterViewInit() {
  }

  openDialog() {
   
      this.router
        .navigate(["/shopnow"], {
          relativeTo: this.activatedRoute,
          queryParams: { type: this.typeSelected },
          queryParamsHandling: "merge",
        })
    
  }
  isLoggedIn() {
    if (
      Object.keys(this.userService.customerData).length &&
      this.userService.customerData.CustomerId &&
      this.user.checkIfUserAuthenticatedOrNot()
    ) {
      return true;
    } else {
      return false;
    }
  }
}
