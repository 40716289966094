import { Component, Injector, OnDestroy, OnInit, Inject } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "../../shared/services/user.service";
import { LoginDialogComponentComponent } from "../login-dialog-component/login-dialog-component.component";
import { NotificationService } from "../services/notification.service";
import { RestApiService } from "../services/restapi.service";

@Component({
  selector: 'app-forgot-dialog',
  templateUrl: './forgot-dialog.component.html',
  styleUrls: ['./forgot-dialog.component.scss']
})
export class ForgotDialogComponent implements OnInit, OnDestroy {
  public forgotPasswordData: any = {
    userName: ''
  };
  public loadingDetails: boolean = true;
  public forgotpasswordtype: string = '';
  public sentEmail: boolean = false;
  public ConfirmPass: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public LoginDialogData: any,
    private inject: Injector,
    private titleService: Title,
    public translate: TranslateService,
    public notificationService: NotificationService,
    public apiServie: RestApiService,
    public userService: UserService) {
    document.body.classList.add("formodal")
  }

  ngOnInit(): void {

    this.translate.get('global_Company_Title').subscribe((text: string) => {
      this.titleService.setTitle(this.translate.instant('pagetitle_forgotpassword') + ' | ' + text);
    });

    if (this.LoginDialogData.forNewPass) {
      this.ConfirmPass = true;
    }
  }

  emailSentForgotPassword() {
    this.loadingDetails = true;
    let link = window.location.origin + '/' + window.location.pathname.split('/')[1] + '/' + 'resetPassword'
    const forgotPasswordRequest = {
      emailaddress: this.forgotPasswordData.userName,
      link: link
    };
    this.apiServie.triggerForgotPasswordProcess(forgotPasswordRequest).subscribe((result) => {
      if (result.status == '200' && result.message == 'Success') {
        this.loadingDetails = false;
        this.forgotPasswordData.userName = '';
        this.sentEmail = true;
      } else {
        this.notificationService.error('error', 'Please Try Again!')
      }
    }, (error) => {
      this.loadingDetails = false;
    });
  }
  backtologin() {
    const Dialog = this.inject.get(MatDialog);
    Dialog.open(this.LoginDialogData, {
      width: "600px",
      autoFocus: false
    });
  }

  ForgotPassword() {
    let customerid = this.LoginDialogData.id
    let req = {
      newPassword: this.forgotPasswordData.userName
    }
    this.apiServie.thirdForgotPasswordProcess(customerid, req).subscribe((data) => {
      if (data.Message == 'Success') {
        this.loadingDetails = false;
        this.forgotPasswordData.userName = '';
        this.sentEmail = true;
      } else {
        this.notificationService.error('error', 'Please Try Again!')
      }
    })
  }

  ngOnDestroy(): void {

    document.body.classList.remove("formodal")
  }
}
