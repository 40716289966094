import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
	name: 'checkValidDate'
})
export class CheckValidDatePipe implements PipeTransform {
	transform(value, format) {
        if (value) {
            var datePipe = new DatePipe("en-US");
            var date = ['1900-01-01', '0001-01-01'].indexOf((new Date(value).toISOString()).substring(0, 10)) > -1 ? (new Date(value).toISOString()).substring(0, 10) : new Date(value).toISOString();
            var checkDate =datePipe.transform(date, format);
            var nullDate =datePipe.transform('0001-01-01T00:00:00', format);
            var invalidDate = datePipe.transform('1900-01-01T00:00:00', format);
            var startDateCheck = (checkDate == nullDate) || (checkDate == invalidDate);
            if (!startDateCheck) {
                return checkDate;
            } else {
                return null;
            }
        } else {
            return null;
        }
	}
}
