import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { UserServiceModal } from "src/app/modals/userservice.modal";
import { LoginDialogComponentComponent } from "../../shared/login-dialog-component/login-dialog-component.component";
import { ConfirmDialogComponent } from "../../shared/model/confirm-dialog/confirm-dialog.component";
import { CartService } from "../../shared/services/cart.service";
import { ConfigService } from "../../shared/services/config.service";
import { ItemsListService } from "../../shared/services/itemsList.service";
import { ProductService } from "../../shared/services/product.service";
import { UserService } from "../../shared/services/user.service";

@Component({
  selector: "app-member",
  templateUrl: "./member.component.html",
  styleUrls: ["./member.component.scss"],
})
export class MembershipComponent implements OnInit {
  customerTypes: Array<any> = [];
  typeSelected: any;
  userService: UserServiceModal;
  memberTypes = [];
  constructor(
    private router: Router,
    public configService: ConfigService,
    private dialog: MatDialog,
    private cartService: CartService,
    private productService: ProductService,
    public translate: TranslateService,
    public user: UserService,
    public activatedRoute: ActivatedRoute,
    public itemService: ItemsListService
  ) {
    this.userService = user.userServiceModal;
    this.typeSelected = this.userService.customerTypeID;
  }

  ngOnInit() {
    this.configService.commonSettings.CustomerTypes.forEach((ct) => {
      this.memberTypes.push({
        memberType: ct.ID,
        typeName: ct.Description,
        iconName: ct.ID == 1 ? "check" : ct.ID == 2 ? "cart" : "star",
        typeDescription: this.descriptions[ct.ID],
      });
    });

  }

  descriptions = {
    "3": "Receive discounted pricing on all products & earn free product through Loyalty Rewards + all the benefits of a Retail Customer.",
    "2": "Enjoy high quality Common Sense products & access to a personal account + personal website.",
    "1": "Earn commissions, bonuses, trainings, business tools, team onboarding + all the benefits of a Preferred customer.",
  };

  carouselData = [
    {
      img: "../../../../assets/images/new/banner-1.jpg",
      startext:
        '"Common Sense Wellness is a once in a lifetime buisness opportunity, and I feel lucky that I didn\'t miss this one!"',
      author: "Connie Ross",
      location: "Meridian, ID",
      small: false,
      medium: false,
      left: true,
      leftbig: true,
      boxTitle: "MEMBERSHIP OPTIONS",
      boxContent: "Select Your Preferred Membership",
    },
    {
      img: "../../../../assets/images/new/banner-2.jpg",
      startext:
        '"Common Sense Wellness is a once in a lifetime buisness opportunity, and I feel lucky that I didn\'t miss this one!"',
      author: "Connie Ross",
      location: "Meridian, ID",
      small: false,
      medium: false,
      left: true,
      leftbig: true,
      boxTitle: "MEMBERSHIP OPTIONS",
      boxContent: "Select Your Preferred Membership",
    },
    {
      img: "../../../../assets/images/new/banner-3.jpg",
      startext:
        '"Common Sense Wellness is a once in a lifetime buisness opportunity, and I feel lucky that I didn\'t miss this one!"',
      author: "Connie Ross",
      location: "Meridian, ID",
      small: false,
      medium: false,
      left: true,
      leftbig: true,
      boxTitle: "MEMBERSHIP OPTIONS",
      boxContent: "Select Your Preferred Membership",
    },
    {
      img: "../../../../assets/images/new/banner-4.jpg",
      startext:
        '"Common Sense Wellness is a once in a lifetime buisness opportunity, and I feel lucky that I didn\'t miss this one!"',
      author: "Connie Ross",
      location: "Meridian, ID",
      small: false,
      medium: false,
      left: true,
      leftbig: true,
      boxTitle: "MEMBERSHIP OPTIONS",
      boxContent: "Select Your Preferred Membership",
    }
  ];

  goToJoin(type) {
    this.selectType(type);
  }

  checkoutRedirection = () => {
    if (localStorage.getItem("isLoggedIn") == "true") {
      this.router.navigate(["/checkout"]);
    } else {
      this.router
        .navigate(["/join"], {
          relativeTo: this.activatedRoute,
          queryParams: { type: this.typeSelected },
          queryParamsHandling: "merge",
        })
        .then(() => {
          window.location.reload();
        });
    }
  };

  selectType(type) {
    if (type == this.typeSelected) {
      sessionStorage.setItem("customerSelected", "true");
      this.checkoutRedirection();
      return;
    }

    if (!this.userService.customerTypeID) {
      this.typeSelected = type;
      sessionStorage.setItem("customerSelected", "true");
      if (this.typeSelected != "0") {
        sessionStorage.setItem("selectedCustomerTypeID", this.typeSelected);
        this.userService.customerTypeID = this.typeSelected;
        localStorage.setItem("userService", JSON.stringify(this.userService));
        // this.productService.change.emit(true);
      } else {
        this.typeSelected = 2;
      }

      this.userService.customerTypeID = this.typeSelected;
      this.collapseSection(this.typeSelected);

      this.itemService.clearcart();
      this.clearCart();
      this.checkoutRedirection();
    } else {
      // changing customer type popup warning
      const dialogRef1 = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: this.translate.instant("update_customer_title"),
          message: this.translate.instant("update_customer_text"),
          takeaction: this.translate.instant("YES"),
          noaction: this.translate.instant("NO"),
        },
        disableClose: true,
        panelClass: "",
      });
      dialogRef1.afterClosed().subscribe((dialogResult) => {
        if (dialogResult) {
          this.typeSelected = type;
          sessionStorage.setItem("customerSelected", "true");
          if (this.typeSelected != "0") {
            sessionStorage.setItem("selectedCustomerTypeID", this.typeSelected);
            this.userService.customerTypeID = this.typeSelected;
            localStorage.setItem(
              "userService",
              JSON.stringify(this.userService)
            );
            // this.productService.changeInProducts.emit(true);
          } else {
            this.typeSelected = 2;
          }

          this.userService.customerTypeID = this.typeSelected;
          this.collapseSection(this.typeSelected);

          this.itemService.clearcart();
          this.clearCart();
          this.checkoutRedirection();
        }
      });
    }
  }

  collapseSection(type) {
    if (type == this.typeSelected) {
      this.userService.sponsorSectionPanel = false;
    }
  }
  // this.collapseSection(null, JSON.parse(sessionStorage.getItem('customerSelected')));

  clearCart() {
    localStorage.getItem("cart.order")
      ? localStorage.removeItem("cart.order")
      : console.log("");
    localStorage.getItem("cart.autoship")
      ? localStorage.removeItem("cart.autoship")
      : console.log("");
    localStorage.getItem("cart.packs")
      ? localStorage.removeItem("cart.packs")
      : console.log("");
  }

  loginFunc() {
    this.dialog.open(LoginDialogComponentComponent, {
      width: "600px",
      autoFocus: false
    });
  }
}
