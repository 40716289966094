import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'productSearchCategory',
    pure: false
})
export class ProductSearchPipeCategory implements PipeTransform {
    transform(items: any[], filter: string): any {
        if (!items || !filter) {
            return items;
        }
        return items.filter((item) => {
            if(filter == 'all'){
                return item;
            }
            return (item.Category?.toLowerCase().indexOf(filter.toLowerCase()) > -1)  ||  (item.Category?.toLowerCase().indexOf(filter.toLowerCase()) > -1);
        });
    }
}
