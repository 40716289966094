import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ConfigService } from "src/app/components/shared/services/config.service";
import { ProductDialogComponent } from "src/app/components/shop/products/product-dialog/product-dialog.component";
import { Cart1Service } from "../services/cart1.service";
import { UtilityService } from "../services/utility.service";

@Component({
  selector: "app-intialorder-carousel",
  templateUrl: "./intialorder-carousel.component.html",
  styleUrls: ["./intialorder-carousel.component.scss"],
})
export class IntialorderCarouselComponent implements OnInit {
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Input("products") products: Array<any> = [];
  public config: SwiperConfigInterface = {};
  public sortByOrder: any; // sorting
  public filterProducts: Array<any>=[]
  contentLoaded = false;
  constructor(
    public configService: ConfigService,
    private dialog: MatDialog,
    private router: Router,
    public cart1Service: Cart1Service,
    private utilityService: UtilityService
  ) {
  }

  ngOnInit() {
    this.products.forEach(item => {
      if (item.SKU != '9999' && item.SKU != '5555' && item.Category != 'Enrollment') {
        this.filterProducts.push(item)
      }
    });
    setTimeout(() => {
      this.contentLoaded = true;
    }, 3000);

    
    this.sortByOrder = this.utilityService.getSortKey()[6];
  }
  ngAfterViewInit() {
    this.config = {
      mousewheel:true,
      observer: true,
      slidesPerView: 3,
      spaceBetween: 16,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1,
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 3,
        },
      },
    };
  }

  public openProductDialog(product) {
    const dialogRef = this.dialog.open(ProductDialogComponent, {
      data: product,
      panelClass: "product-dialog",
    });
    dialogRef.afterClosed().subscribe((product) => {
      if (product) {
        this.router.navigate(["/products", product.id, product.name]);
      }
    });
  }

  // Add to cart
  public addToCart(product) {
    this.cart1Service.addToCart(product, true, "", false, false, false, true);
  }
}
 