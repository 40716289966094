export function getBaseLocation() {
    let registerRoute = ['/home','/signup', '/products/[a-zA-Z0-9]', '/product/[a-zA-Z0-9]', '/product-detail/[a-zA-Z0-9]', '/application','/join',
        '/pages/[A-Za-z]', '/complete', '/main','/checkout','/orderhistory', '/manageautoship', '/autoorderhistory', '/complete', '/404', '/order-success', '/signup', '/orderinvoice', '/forgotpassword', 'shipping', '/membership'];
    if (registerRoute.includes(location.pathname)) {
        return '/samspace';
    }
    if (location.pathname.includes('pages/') && location.pathname.includes('checkout/')) {
        let routeMatch = registerRoute.some((item) => {
            return new RegExp(item).test(location.pathname);
        });
        if (routeMatch) {
            return '/samspace';
        }
    }
    let paths: string[] = location.pathname.split('/').splice(1, 1);

    let afterpaths:string[] = location.pathname.split('/').splice(2,1);

    let afterpath:string = ( afterpaths[0]);
    let basePath: string = (paths && paths[0]) || 'samspace';
    return '/' + basePath;
}

export function getafterLocation() {
  let registerRoute = ['/home','/signup', '/products/[a-zA-Z0-9]', '/product/[a-zA-Z0-9]', '/product-detail/[a-zA-Z0-9]', '/application','/join',
      '/pages/[A-Za-z]', '/complete', '/main','/checkout','/orderhistory', '/manageautoship', '/autoorderhistory', '/complete', '/404', '/order-success', '/signup', '/orderinvoice', '/forgotpassword', 'shipping', '/membership'];
  if (registerRoute.includes(location.pathname)) {
      return '/samspace';
  }
  if (location.pathname.includes('pages/') && location.pathname.includes('checkout/')) {
      let routeMatch = registerRoute.some((item) => {
          return new RegExp(item).test(location.pathname);
      });
      if (routeMatch) {
          return '/samspace';
      }
  }
  let paths: string[] = location.pathname.split('/').splice(1, 1);

  let afterpaths:string[] = location.pathname.split('/').splice(2,1);

  let afterpath:string = ( afterpaths[0]);
  return '/' + afterpath;
}
