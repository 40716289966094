import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Cart1Service } from "./cart1.service";
import { ProductService } from "./product.service";

@Injectable()
export class LoggedInAuthGuard implements CanActivate {

    constructor(private cookieService: CookieService, private _router: Router) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        if (this.cookieService.get('X-Auth')) {
            this._router.navigate(['/home']);
            return false
        } else {
            return true;
        }
    }
}
