import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-intro-carousel",
  templateUrl: "./intro-carousel.component.html",
  styleUrls: ["./intro-carousel.component.scss"],
})
export class IntroCarouselComponent implements OnInit {
  showoptions:boolean
  @Input() displayForCarousel = [];



  constructor() {}

  ngOnInit(): void {
    if(this.displayForCarousel.length<=1){
      this.showoptions = false
    }else{
      this.showoptions=true
    }
  }
}
